<template>
  <div class="setting">
    <div class="card flex-box">
      <div class="breadcrumb">
        <i class="el-icon-arrow-left" @click="back"></i>
        <span>导出试卷</span>
      </div>
    </div>
    <div class="card">
      <div class="title">{{ queryData.examName }}</div>
      <div class="sub-title">1、选择导出范围：</div>
      <div class="label">
        <span>选择班级：</span>

        <el-button type="text" @click="checkAllClassChange">全选</el-button>
        <el-button type="text" @click="classReverse">反选</el-button>
        <el-button
          type="text"
          @click="
            checkedClass = [];
            checkAllClass = false;
          "
          >取消全部</el-button
        >
      </div>
      <div class="checkList">
        <el-checkbox-group
          v-model="checkedClass"
          @change="handleCheckedClassChange"
        >
          <el-checkbox
            v-for="(item, index) in classList"
            :key="index"
            :label="item.name"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div class="label">
        <span>选择科目：</span>
        <el-button type="text" @click="checkAllsubjectChange">全选</el-button>
        <el-button type="text" @click="subjectReverse">反选</el-button>
        <el-button
          type="text"
          @click="
            checkedsubject = [];
            checkAllsubject = false;
          "
          >取消全部</el-button
        >
      </div>
      <div class="checkList">
        <el-checkbox-group
          v-model="checkedsubject"
          @change="handleCheckedsubjectChange"
        >
          <el-checkbox
            v-for="(item, index) in subjectList"
            :key="index"
            :label="item.id"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div class="check-item">
        <div class="label">
          <span>答题卡考号放在最前：</span>
        </div>
        <div class="checkList">
          <el-radio-group v-model="studentExamIdInFront">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="check-item">
        <div class="label">
          <span> 科目目录是否放在班级目录前面：</span>
        </div>
        <div class="checkList">
          <el-radio-group v-model="subjectInFront">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="check-item">
        <div class="label">
          <span> 是否在目录里增加任课教师一级：</span>
        </div>
        <div class="checkList">
          <el-radio-group v-model="addTeacherName">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="sub-title">2、生成文件包并下载：</div>
      <div class="tag">
        <i class="el-icon-warning"></i>
        注意：生成试卷的文件包需要花费较长时间，请您耐心等待，选择的班级、科目越多，导出时间越长；请完全下载成功后，再导出下一个文件包。
      </div>
      <div class="submit">
        <el-button type="primary" :loading="loading" @click="exportAll"
          >导出</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {
  getstatsubjectoption,
  getstatclassoption,
} from "@/core/api/academic/common";
import { run } from "./exportMap";
export default {
  name: "Setting",
  data() {
    return {
      queryData: { ...this.$route.query },
      isIndeterminate: false,
      checkAllClass: false,
      classList: [],
      checkedClass: [],
      isIndeterminatesub: false,
      checkAllsubject: false,
      subjectList: [],
      checkedsubject: [],
      loading: false,
      studentExamIdInFront: 0,
      subjectInFront: 0,
      addTeacherName: 0,
    };
  },
  mounted() {
    this.getstatclassoptionData();
    this.getstatsubjectoption();
  },
  methods: {
    async getstatsubjectoption() {
      const res = await getstatsubjectoption({
        id: this.queryData.statId,
        hasTotal: 0,
      });
      this.subjectList = res.data.data;
    },
    async getstatclassoptionData() {
      const res = await getstatclassoption({ id: this.queryData.statId });
      this.classList = res.data.data;
    },
    back() {
      this.$router.go(-1);
    },
    checkAllClassChange() {
      this.checkedClass = this.classList.map((item) => item.name);
    },
    handleCheckedClassChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.classList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.classList.length;
    },
    classReverse() {
      const data = this.classList.filter(
        (item) => !this.checkedClass.includes(item.name)
      );
      this.checkedClass = data.map((item) => item.name);
    },
    checkAllsubjectChange() {
      this.checkedsubject = this.subjectList.map((item) => item.id);
    },
    handleCheckedsubjectChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.subjectList.length;
      this.isIndeterminatesub =
        checkedCount > 0 && checkedCount < this.subjectList.length;
    },
    subjectReverse() {
      const data = this.subjectList.filter(
        (item) => !this.checkedsubject.includes(item.id)
      );
      this.checkedsubject = data.map((item) => item.id);
    },
    async exportAll() {
      if (!this.checkedClass.length) {
        this.$message({
          showClose: true,
          message: "请选择班级",
          type: "error",
        });
      } else if (!this.checkedsubject.length) {
        this.$message({
          showClose: true,
          message: "请选择科目",
          type: "error",
        });
      } else {
        this.loading = true;
        try {
          await run({
            ...this.queryData,
            detail: 0,
            subjectId: this.checkedsubject.toString(),
            classNum: this.checkedClass.toString(),
            studentExamIdInFront: this.studentExamIdInFront,
            subjectInFront: this.subjectInFront,
            addTeacherName: this.addTeacherName,
          });
          this.loading = false;
        } catch {
          this.loading = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .breadcrumb {
    font-size: 16px;
    color: #080a09;
    font-weight: bold;
    display: flex;
    align-items: center;
    i {
      font-size: 20px;
      color: #080a09;
      font-weight: bold;
      cursor: pointer;
    }
    span {
      margin-right: 8px;
    }
  }
}
.search {
  margin-bottom: 24px;
}
.details {
  color: #3f87f4;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.title {
  font-size: 16px;
  color: #080a09;
  text-align: center;
  font-weight: bold;
  margin-bottom: 24px;
}
.one {
  border-bottom: 1px solid #ebeef5;
  text-align: center;
}
.two {
  text-align: center;
}
::v-deep .cell {
  padding: 0;
  padding-left: 0 !important;
}
.dashboard {
  margin-bottom: 24px;
  font-size: 16px;
  color: #080a09;
}
.sub-title {
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: bold;
  color: #2474ed;
}
.label {
  margin-bottom: 24px;
}
.checkList {
  .el-checkbox {
    margin-bottom: 24px;
    margin-right: 24px;
    min-width: 80px;
  }
}
.tag {
  border: 1px dashed #2474ed;
  box-sizing: border-box;
  padding: 13px;
  color: #737677;
  background-color: #f2f8fe;
  i {
    margin-right: 5px;
    color: #2474ed;
  }
}
.submit {
  margin-top: 50px;
  text-align: center;
}
.check-item {
  margin-bottom: 18px;
}
</style>
